//
// user-variables.scss
// Use this to override Bootstrap and Around variables
//

// Example of a variable override to change body background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #f6f9fc;

$navbar-nav-link-padding-x: 0.5rem !default;
$font-family-sans-serif: "Twemoji Country Flags", "Inter", sans-serif !default;

// $font-family-sans-serif:
//   system-ui,
//   -apple-system,
//   "Segoe UI",
//   Roboto,
//   "Helvetica Neue",
//   "Noto Sans",
//   "Liberation Sans",
//   Arial,
//   sans-serif,
//   "Apple Color Emoji",
//   "Segoe UI Emoji",
//   "Segoe UI Symbol",
//   "Noto Color Emoji" !default;

$nav-link-color: var(--ar-gray-900) !default;

// Primary Button Colors
$primary: #25ad71 !default;
$primary-hover: #0c9458 !default;
$primary-active: #0c9458 !default;

// $primary: #066040 !default;
// $primary-hover: #004727 !default;
// $primary-active: #004727 !default;

// $primary: #087750 !default;
// $primary-hover: #004727 !default;
// $primary-active: #004727 !default;

// Warning Button Colors
$warning: #ffb900 !default;
$warning-hover: #e6a000 !default;
$warning-active: #e6a000 !default;

// Info Button Colors
$info: #2370f6 !default;
$info-hover: #0a57dd !default;
$info-active: #0a57dd !default;

// Success Button Colors
$success: #1de290 !default;
$success-hover: #0dca7b !default;
$success-active: #0dca7b !default;

// Danger Button Colors
$danger: #f64646 !default;
$danger-hover: #dd2d2d !default;
$danger-active: #dd2d2d !default;

$border-radius: 0rem !default;
