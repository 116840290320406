// Ustalanie globalnej zmiennej dla skalowania
$base-mouse-size: 0.8;

:root {
  --mouse-size: #{$base-mouse-size};
}

.scroll-down-btn {
  display: inline-block;
  text-decoration: none;
  
  p {
    margin-top: 1.25rem;
    color: white;
    font-size: 0.775rem;
    font-weight: 400;
    letter-spacing: 0.275rem;
    text-indent: 0.275rem;
    animation:
    colorText 5s ease-out infinite,
    nudgeText 5s ease-out infinite;
    font-weight: 300;
    letter-spacing: 0.125rem;
  }
}

.mouse {
  background: #2c3e32 linear-gradient(transparent 0%, transparent 50%, white 50%, white 100%);
  position: relative;
  width: calc(38px * var(--mouse-size));
  height: calc(65px * var(--mouse-size));
  margin: 0 auto;
  border-radius: 6rem;
  background-size: 100% 200%;
  animation:
  colorSlide 5s linear infinite,
  nudgeMouse 5s ease-out infinite;
  
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }

  &:before {
    width: calc(34px * var(--mouse-size));
    height: calc(61px * var(--mouse-size));
    background-color: #121519;
    border-radius: 6rem;
  }

  &:after {
    background-color: white;
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 100%;
    animation: trackBallSlide 5s linear infinite;
  }
}

html[data-bs-theme="light"] {
  .scroll-down-btn--invert {
    filter: invert(1) grayscale(1);
    .mouse {
      &:before {
        background-color: black;
        width: calc(36px * var(--mouse-size));
        height: calc(63px * var(--mouse-size));
      }
    }
  }
}

@keyframes colorSlide {
  0% { background-position: 0% 100%; }
  20% { background-position: 0% 0%; }
  21% { background-color: #2c333e; }
  29.99% {
    background-color: white;
    background-position: 0% 0%;
  }
  30% {
    background-color: #2c333e;
    background-position: 0% 100%;
  }
  50% { background-position: 0% 0%; }
  51% { background-color: #2c333e; }
  59% {
    background-color: white;
    background-position: 0% 0%;
  }
  60% {
    background-color: #2c333e;
    background-position: 0% 100%;
  }
  80% { background-position: 0% 0%; }
  81% { background-color: #2c333e; }
  90%, 100% {
    background-color: white;
  }
}

// Animacje ze skalowaniem tylko translateY
@keyframes trackBallSlide {
  0% {
    opacity: 1;
    transform: scale(1) translateY(calc(-20px * var(--mouse-size)));
  }
  6% {
    opacity: 1;
    transform: scale(0.9) translateY(calc(5px * var(--mouse-size)));
  }
  14% {
    opacity: 0;
    transform: scale(0.4) translateY(calc(40px * var(--mouse-size)));
  }
  15%,
  19% {
    opacity: 0;
    transform: scale(0.4) translateY(calc(-20px * var(--mouse-size)));
  }
  28%,
  29.99% {
    opacity: 1;
    transform: scale(1) translateY(calc(-20px * var(--mouse-size)));
  }
  30% {
    opacity: 1;
    transform: scale(1) translateY(calc(-20px * var(--mouse-size)));
  }
  36% {
    opacity: 1;
    transform: scale(0.9) translateY(calc(5px * var(--mouse-size)));
  }
  44% {
    opacity: 0;
    transform: scale(0.4) translateY(calc(40px * var(--mouse-size)));
  }
  45%,
  49% {
    opacity: 0;
    transform: scale(0.4) translateY(calc(-20px * var(--mouse-size)));
  }
  58%,
  59.99% {
    opacity: 1;
    transform: scale(1) translateY(calc(-20px * var(--mouse-size)));
  }
  60% {
    opacity: 1;
    transform: scale(1) translateY(calc(-20px * var(--mouse-size)));
  }
}

@keyframes nudgeMouse {
  0%,
  30%,
  60%,
  90% {
    transform: translateY(calc(0px * var(--mouse-size)));
  }
  20%,
  50%,
  80% {
    transform: translateY(calc(8px * var(--mouse-size)));
  }
}

@keyframes nudgeText {
  0%,
  30%,
  60%,
  90% {
    transform: translateY(calc(0px * var(--mouse-size)));
  }
  20%,
  50%,
  80% {
    transform: translateY(calc(2px * var(--mouse-size)));
  }
}

@keyframes colorText {
  21%,
  51%,
  81% {
    color: #2c333e;
  }
  30%,
  60%,
  90% {
    color: white;
  }
}
