* {
  text-underline-offset: 4px;
}

.img--dark,
.logo-img--dark {
  display: none;
}

.logo-img {
  height: 44px;
  width: auto;
  margin-right: 24px;
}

html[data-bs-theme="dark"] {
  .img--light,
  .logo-img--light {
    display: none;
  }
  .img--dark,
  .logo-img--dark {
    display: initial;
  }
  .invert-on-dark-mode {
    filter: invert(1);
  }
  .only-on-dark-text-gradient {
    background: linear-gradient(
      30deg,
      #2370f6 0%,
      hsl(154 65% 55% / 1) 45%,
      #ab23f6 100%
    ); // #25ad71(primary) converted to hsl and change the lightness to 55%

    // #2582ad 7%,
    // hsl(154 65% 55% / 1) 43%,
    // #7d25ad 96% // old

    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(0, 0, 0, 0);
  }
}

.bg-transparent-nav.transparent-onDark:not(.navbar-stuck) {
  .nav-link,
  .nav-item > a {
    color: #fff;
    &:hover {
      color: #ddd;
      a {
        color: #ddd;
      }
    }
  }

  .nav-item {
    &:hover {
      color: #ddd;
    }
  }

  .nav-item:hover > .nav-link:not(.disabled),
  .nav-item .nav-link.show:not(.disabled) {
    color: #ddd;
  }
  .dropdown:hover > .dropdown-toggle:not(.disabled) {
    color: #ddd;
  }
}

@media (max-width: 900px) {
  .bg-transparent-nav.transparent-onDark:not(.navbar-stuck) {
    .nav-link,
    .nav-item > a {
      color: #000;
      &:hover {
        color: #333;
        a {
          color: #333;
        }
      }
    }

    .nav-item {
      &:hover {
        color: #333;
      }
    }

    .nav-item:hover > .nav-link:not(.disabled),
    .nav-item .nav-link.show:not(.disabled) {
      color: #333;
    }
    .dropdown:hover > .dropdown-toggle:not(.disabled) {
      color: #333;
    }
  }
}

.navbar-stuck {
  border-bottom: 1px solid rgba(77, 77, 77, 0.1);
}

.bg-transparent-nav.transparent-onDark:not(.navbar-stuck) {
  .logo-img--dark {
    display: block;
  }
  .logo-img--light {
    display: none;
  }
}

// h1,
// h2,
// .display-1,
// .display-2,
// .display-3 {
//   font-weight: 700;
//   line-height: 1.05;
//   letter-spacing: -1.5px;
// }

// .main-title-pawel-idea {
//   h2,
//   .display-3 {
//     letter-spacing: 0.45rem;
//     @media (max-width: 900px) {
//       letter-spacing: 0.17rem;
//     }
//   }
//   h1,
//   .h2 {
//     padding-left: 10px;
//     font-weight: 200;
//     letter-spacing: 1.48rem;
//     @media (max-width: 900px) {
//       padding-left: 2px;
//       letter-spacing: 0.53rem;
//     }
//   }
// }

// .lg-on {
//   overflow: hidden;
// }

// .form-group:has(input[required]) .form-label::after {
//   content: " *";
//   color: red;
// }

// .partner-img {
//   max-width: 192px;
//   max-height: 70px;
//   object-fit: contain;
//   &.even-smaller {
//     max-width: 140px;
//     max-height: 60px;
//   }
// }

.mode-switch {
  .form-check-label,
  .form-check-input {
    cursor: pointer;
  }
}
.card-hover-primary {
  i,
  svg {
    transition: color 0.25s ease-in-out;
  }

  &:hover {
    i,
    svg {
      color: var(--ar-heading-color) !important;
    }
  }
}

html[data-bs-theme="light"] {
  .web-studio-bg {
    filter: invert(0.8);
    // transform: scaleX(-100%);
  }
}

.grayscale-effect {
  transition-duration: 3s;
  filter: grayscale(0.1);
  opacity: 0.96;
  // .zoom-effect-img {
  //   background: var(--ar-primary);
  //   transition-duration: 0.3s;
  //   img {
  //     transition-duration: 0.3s;
  //     mix-blend-mode: multiply;
  //   }
  // }

  &:hover {
    transition-duration: 0.3s;
    filter: grayscale(0);
    opacity: 1;
    .zoom-effect-img {
      // transition-duration: 0.3s;
      // background: #25ad7100;
    }
  }
}

.nav-link-disabled {
  &:hover {
    color: var(--ar-nav-link-color);
  }
}

.fs-7 {
  font-size: 0.85rem;
}
.fs-8 {
  font-size: 0.75rem;
}

.bg-grid {
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  background: url("../../assets/images/oferta/grid-light.svg");
}

html[data-bs-theme="dark"] {
  .bg-grid {
    background: url("../../assets/images/oferta/grid-dark.svg");
  }
}

.header-link.active {
  color: var(--ar-primary);
}

.article-content {
  h2 {
    margin-top: 64px;
  }
  h3 {
    margin-top: 48px;
  }
  hr {
    margin-bottom: 24px;
  }
}
